






import Vue from "vue";
import { Form, Field, Checkbox, CheckboxGroup, Toast } from "vant";

export default Vue.extend({
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  methods: {
    go() {
      Toast.success({
        message: "111",
        onClose: () => {
          this.$router.go(-1);
        }
      });
    }
  }
});
